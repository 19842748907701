<div
  [ngClass]="{
    'info-block': true,
    info: variety() === 'info',
    success: variety() === 'success',
    error: variety() === 'error',
    'without-margin': withoutMargin(),
  }"
>
  <!-- Иконка - восклицательный знак -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    *ngIf="variety() !== 'success'"
  >
    <path
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      stroke="#050609"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 8V12"
      stroke="#050609"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 16H12.01"
      stroke="#050609"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  <!-- Иконка - галочка -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    *ngIf="variety() === 'success'"
  >
    <path
      d="M12.0039 22C17.5268 22 22.0039 17.5228 22.0039 12C22.0039 6.47715 17.5268 2 12.0039 2C6.48106 2 2.00391 6.47715 2.00391 12C2.00391 17.5228 6.48106 22 12.0039 22Z"
      fill="#6F9305"
    />
    <path
      d="M9 12L11 14L15 10"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  {{ description() }}
</div>

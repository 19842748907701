import { NgClass, NgIf } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  selector: 'app-info-block',
  standalone: true,
  imports: [NgClass, NgIf],
  templateUrl: './info-block.component.html',
  styleUrl: './info-block.component.scss',
})
export class InfoBlockComponent {
  description = input.required<string>();
  variety = input<'info' | 'success' | 'error'>('info');
  withoutMargin = input<boolean>(false);
}
